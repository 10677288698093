
  const common = {
    cardColors: {
      ACCEPTED: '#c6a213',
      COMPLETED: '#d36b00',
      APPROVED: '#12db6d',
      REJECTED: '#757575',
      PENDING: '#782b54', // Older - #692093
      EXPIRED: '#757575',
      ACTIVE: '#782b54',
      UPLOADED: '#009654',
      STARTED: '#db4437',
      ATTENTION: '#cb4f40'
    },
    statusSortOrder: {
      ACCEPTED: 'updated_at-ASC',
      COMPLETED: 'updated_at-ASC',
      APPROVED: 'updated_at-DESC',
      REJECTED: 'updated_at-DESC',
      PENDING: 'id-ASC',
      ATTENTION: 'updated_at-DESC',
      EXPIRED: 'expire_date_time-DESC'
    },

    getDefaultImage (type) {
      switch (type) {
        default:
          return '/img/pickl-logo.png'
      }
    },

    changeDefaultImage (event, type) {
      if (event && event.target && event.target instanceof HTMLImageElement) event.target.src = this.getDefaultImage(type)
    },

    encodeData (data) {
      return Object.keys(data)
        .map(function (key) {
          return [key, data[key]].map(encodeURIComponent).join('=')
        })
        .join('&')
    },
    delay (callback, ms) {
      return () => {
        let context = this
        clearTimeout(this.timer)
        this.timer = setTimeout(function () {
          callback.apply(context)
        }, ms || 0)
      }
    },
    getStatusTitle (status) {
      switch (status.trim()) {
        case 'COMPLETED':
          // if (store.state.userData && store.state.userData.role === 'super-admin') return 'Awaiting Approval'
          // else
          return 'Awaiting Approval'
        case 'PENDING':
          return 'Launched  Activities'
        case 'ATTENTION':
          return 'Attention Needed'
        default:
          return status.toLowerCase() + ''
      }
    }
  }
export default common;