import BrandLayout from '@/layouts/BrandLayout.vue'

export default {
  path: '/',
  component: BrandLayout,
  redirect: '/brand/login',
  children: [
    {
      path: '/brand/build-your-campaign',
      name: 'build-your-campaign',
      component: () => import('@/views/Brand/BuildYourCampaign.vue'),
      meta: {
        requiresAuth: false,
        title: 'Build your campaign | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/campaign-settings',
      name: 'campaign-settings',
      component: () => import('@/views/Brand/CampaignSettings.vue'),
      meta: {
        requiresAuth: false,
        title: 'campaign-settings | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/packages-of-benefit',
      name: 'packages-of-benefit',
      component: () => import('@/views/Brand/PackagesOfBenefit.vue'),
      meta: {
        requiresAuth: false,
        title: 'Packages of benefit | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/geo-location-type',
      name: 'geo-location-type',
      component: () => import('@/views/Brand/GeoLocationType.vue'),
      meta: {
        requiresAuth: false,
        title: 'Geo location type | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/activity-incentives-type',
      name: 'activity-incentives-type',
      component: () => import('@/views/Brand/ActivityIncentivesType.vue'),
      meta: {
        requiresAuth: false,
        title: 'Activity incentives type | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/use-coupon-free-ticket',
      name: 'use-coupon-free-ticket',
      component: () => import('@/views/Brand/UseCouponOrFreeTicket.vue'),
      meta: {
        requiresAuth: false,
        title: 'Use coupon or free ticket | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/saved-campaign-settings',
      name: 'saved-campaign-settings',
      component: () => import('@/views/Brand/SavedCampaignSettings.vue'),
      meta: {
        requiresAuth: false,
        title: 'Saved campaign settings | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/where-you-want-to-launch',
      name: 'where-you-want-to-launch',
      component: () => import('@/views/Brand/WhereYouWantToLaunch.vue'),
      meta: {
        requiresAuth: false,
        title: 'Where you want to launch | Brand',
        breadcrumb: 'Login'
      }
    },
    {
      path: '/brand/any-user-location',
      name: 'any-user-location',
      component: () => import('@/views/Brand/AnyUserLocation.vue'),
      meta: {
        requiresAuth: false,
        title: 'Any user location | Brand',
        breadcrumb: 'Login'
      }
    },
    



  ]
}
