import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import App from './App.vue'
import common from './properties.js'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store.js'



router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
  })
const app = createApp(App)
app.config.globalProperties.$common = common
app.use(router)

app.use(store)
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
app.use(VueAxios, axios)
console.log(store)
// let s = store
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     let userData = s.state.userData

//     if (userData === null) {
//       let redirectPath = ''
//       let pathname = window.location.pathname
//       console.log(pathname)
//       let uri = window.location.href.split('?')
//       let getVars = {}
//       if (uri.length === 2) {
//         let vars = uri[1].split('&')
//         vars.forEach(function (v) {
//           let tmp = v.split('=')
//           if (tmp.length === 2) {
//             getVars[tmp[0]] = tmp[1]
//           }
//         })
//         redirectPath = '?redirect=' + pathname + '?' + encodeURIComponent(uri[1])
//       } else {
//         redirectPath = '?redirect=' + pathname
//       }
//       return next({
//         path: '/brand/login' + redirectPath
//       })
//     }
//   }
//   if (to.matched.some(record => record.meta.userType)) {
//     let userData = s.state.userData
//     if (userData && to.meta.userType !== userData.role) {
//       if (userData.role === 'brand') {
//         return next({
//           path: '/brand/dashboard'
//         })
//       } else if (userData.role === 'super-admin') {
//         return next({
//           path: '/sadmin/dashboard'
//         })
//       } else if (userData.role === 'staff') {
//         return next({
//           path: '/staff/dashboard'
//         })
//       }
//     }
//   } else if (s.state.userData && s.state.userData.role) {
//     let pathname = window.location.pathname
//     if (s.state.userData.role === 'brand' || s.state.userData.role === 'brand-manager' || s.state.userData.role === 'brand-billing-manager') {
//       // let redirectPath = ''
//       let uri = window.location.href.split('?')
//       if (uri.length === 2) {
//         let vars = uri[1].split('&')
//         vars.forEach(function (v) {
//           let tmp = v.split('=')
//           if (tmp.length === 2) {
//             if (tmp[0] === 'refer') {
//               return next({
//                 path: '/brand/profile'
//               })
//             }
//           }
//         })
//       } else if (pathname.indexOf('/overview/') === 0) {
//         console.log('here we are')
//       } else {
//         return next({
//           path: '/brand/dashboard'
//         })
//       }
//     } else if (pathname.indexOf('/overview/') === 0) {
//       console.log('here we are')
//     } else {
//       return next({
//         path: '/'
//       })
//     }
//   }
//   document.title = to.meta.title
//   next()
// })

axios.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'application/json'
  let accessToken = store.state.accessToken
  if(store.state.loginAsBrand) {
    accessToken = store.state.brandAccessToken 
  }
  config.headers.Authorization = 'Bearer ' + accessToken
  if (store.state.userData !== null) {
    console.log(store)
    if (store.state.userData.role === 'brand' || store.state.userData.role === 'brand-manager' || store.state.userData.role === 'brand-billing-manager') {
      config.headers['user-type'] = 'brand'
    } else if (store.state.userData.role === 'super-admin') {
      config.headers['user-type'] = 'sadmin'
    } else if (store.state.userData.role === 'staff') {
      config.headers['user-type'] = 'staff'
    }
  }
  return config;
});

app.mount('#app')
